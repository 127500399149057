import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 420 200"
    >
      <defs>
        <marker orient="auto" overflow="visible" refX="0" refY="0">
          <path stroke="#000" strokeWidth="0.5" d="M3-3l-6 6m3-8V5"></path>
        </marker>
        <pattern width="8" height="8" x="0" y="0" patternUnits="userSpaceOnUse">
          <path stroke="#000" strokeWidth="0.25" d="M8 4L4 8"></path>
          <path stroke="#000" strokeWidth="0.25" d="M6 2L2 6"></path>
          <path stroke="#000" strokeWidth="0.25" d="M4 0L0 4"></path>
        </pattern>
      </defs>
      <g>
        <g transform="matrix(.26458 0 0 .26458 279.461 -382.435)">
          <ellipse
            cx="-745.33"
            cy="1826.598"
            fill="#58a5be"
            fillOpacity="0.069"
            stroke="#000"
            strokeOpacity="0"
            strokeWidth="2.151"
            rx="214.907"
            ry="208.83"
          ></ellipse>
          <path
            fill="#28a745"
            strokeWidth="0.879"
            d="M-829.558 1933.351c13.262-36.533 24.736-35.992 37.452-43.854 18.74-11.585 30.448-20.042 34.345-20.482 3.297-.371 10.734 21.382 14.21 30.211 5.38 13.667 3.037 15.41 11.567 22.243 14.923 11.954 30.347 13.068 32.486 15.813 8.087 10.377 3.589 18.816-1.463 26.549-1.536 2.351-20.36 4.316-28.103 6.003-4.992 1.088-10.143 7.887-31.415 21.536-21.177 13.588-26.422 5.154-57.506-20.436-15.92-18.847-11.96-18.636-11.573-37.583z"
          ></path>
          <path
            fill="#083a42"
            strokeWidth="0.879"
            d="M-581.059 1833.02c-4.66-4.353-13.396-12.252-19.414-17.553-6.018-5.301 0 0-18.848-17.713-10.449-13.863-27.151-12.94-35.065-22.2-7.414-8.9-17.057-11.375-17.11-22.077-.045-9.047-1.785-24.989 18.927-41.268 22.582-17.751 35.051-21.15 59.08-10.437 20.827 9.287 35.361 9.417 48.893 50.492 10.534 31.973 8.29 23.477 11.62 41.757 2.95 22.169 3.166 50.73.652 63.192-13.139 24.857-26.79-4.733-48.735-24.193z"
          ></path>
          <path
            fill="#0c5460"
            strokeWidth="0.879"
            d="M-693.514 1966.95c0-1.592.858-12.58 6.964-15.867 2.458-1.323 8.922-22.819 17.4-28.028 21.625-13.286 22.587-11.47 32.978-9.823 5.775.915 22.57 6.673 27.112 8.895 6.137 3.003 12.742 17.212 15.705 22.796 1.764 3.325 17.4 11.797 16.35 13.062-4.096 4.936-21.62 20.946-40.35 35.098-19.464 14.708-38.308 24.56-48.156 25.55-22.01 2.215-28.003-49.813-28.003-51.683z"
          ></path>
          <path
            fill="#11694f"
            strokeWidth="0.879"
            d="M-603.521 1919.43c-14.287-13.155-35.353-8.984-46.23-24.102-7.173-9.968-6.62-8.927-6.41-20.729-.404-19.595 1.562-66.45 13.391-75.114 8.595-3.266 23.511 4.668 34.753 15.323 4.236 4.014 19.73 27.449 25.318 31.387 10.147 7.151 39.946 26.161 42.652 38.608 1.2 5.515-10.033 27.414-14.094 36.25-6.632 14.432-7.937 15.121-13.632 23.342-8.997 12.983-26.673-16.609-35.748-24.965z"
          ></path>
          <path
            fill="#168967"
            strokeWidth="0.879"
            d="M-674.126 1721.582c-2.81 2.858-42.33 32.881-52.002 26.586-10.704-6.968 10.963-76.956 1.488-80.34-14.606-5.213-19.316-8.997-19.995-16.06 2.773-12.648 19.264-32.627 27.595-32.062 19.43 2.721 9.095.446 37.213 7.959 9.114 2.435 30.873 9.68 50.944 23.379 5.938 4.053 44.418 34.574 44.364 37.356-.44 22.596-22.758-1.172-40.692 2.017-30.726 5.466-18.106-.175-48.915 31.165z"
          ></path>
          <path
            fill="#106e7d"
            strokeWidth="0.879"
            d="M-789.295 2030.47c-2.474-1.07-.528-8.083 1.813-11.87 2.213-3.58 5.282-5.89 15.671-11.796 9.33-5.304 16.777-11.082 29.882-23.188 10.54-9.736 14.664-9.948 18.927-9.929 14.763.065 17.258-.325 22.441 12.439 2.812 6.923 3.836 13.996 4.934 16.478 1.099 2.482 6.25 23.649 5.942 24.45-1.068 2.782-19.359 5.59-45.556 8.364-28.302 2.995-50.857-3.563-54.054-4.947z"
          ></path>
          <path
            fill="#17a2b8"
            strokeWidth="0.848"
            d="M-732.673 1694.552c-14.07 91.01-62.594-.698-73.465-5.893-10.87-5.195-24.903-11.516-31.185-14.047-14.181-5.713-22.166-9.4-23.43-10.818-1.327-1.49 3.684-15.83 7.43-17.856 20.743-11.222 31.676-15.154 49.863-19.954 14.89-3.93 19.357-5.193 35.887-6.938 16.204-1.71 44.172.1 28.643 9.53-15.98 9.702-12.861 19.209-8.82 39.941 2.036 10.448 19.377-1.778 15.077 26.035z"
          ></path>
          <path
            fill="#13889b"
            strokeWidth="0.879"
            d="M-732.964 1908.944c-4.694-6.558-10.687-29.427-13.404-34.706-2.788-5.418-7.09-12.088-9.877-15.31-8.36-9.671-10.012-12.273-10.012-15.773 0-3.795 2.847-8.526 10.07-16.732 2.765-3.14 7.399-9.16 10.298-13.376 10.088-14.67 39.112-56.045 59.06-50.873 11.39 2.953 17.745 9.64 22.365 18.386 13.613 25.766 3.26 55.028 3.689 67.735.505 14.96-1.972 31.254-5.489 55.71-.92 6.395-7.404 14.532-13.618 19.388-26.532 12.505-38.063.263-53.082-14.45z"
          ></path>
          <path
            fill="#28a745"
            strokeWidth="0.881"
            d="M-820.345 1890.157c-5.405-5.798-12.744-62.126 2.206-73.767 12.973-6.415 45.583 1.063 53.002 7.62 5.24 4.63-14.15 14.539-.895 32.213 8.742 11.655-47.019 40.708-54.313 33.934z"
          ></path>
          <path
            fill="#61c0cf"
            strokeWidth="0.879"
            d="M-863.076 1894.754c-20.362 1.174-49.585 12.468-56.553 3.933-13.6-16.658 5.378-20.03 21.33-39.158 4.91-5.886 9.643-16.456 15.901-30.083 2.42-5.267 11.074-26.737 12.783-31.317 4.525-12.128 1.28-45.078 43.482-31.575 27.005 8.64 99.293 7.63 81.122 29.662-33.847 41.04-72.657-11.544-83.798 22.376-4.293 13.07-11.278 24.95-4.404 41.471 1.893 4.548 3.113 8.943 3.122 11.241.023 5.936 11.062 20.955-2.324 24.193-11.57 2.798-12.945-1.765-30.66-.743z"
          ></path>
          <path
            fill="#4ab563"
            strokeWidth="0.879"
            d="M-806.254 1761.672c-2.658-.857-39.994-12.502-47-15.676-7.006-3.174-95.36 15.017-73.422-30.529 9.22-19.037 24.339-32.363 38.887-44.928 16.935-11.416 22.525-4.1 27.397-1.366 8.85 4.967 30.105 16.116 42.168 21.715 16.582 7.695 20.425 14.918 21.633 15.27 3.324.968 24.215 40.91 52.394 35.644 9.625-1.798 32.088 28.1 15.974 35.782-13.517 6.444-70.494-13.48-78.031-15.912z"
          ></path>
          <path
            fill="#86cfda"
            strokeWidth="0.879"
            d="M-917.243 1952.091c-1.581-1.156-3.57-24.294 2.466-36.356 3.215-6.423 33.81-16.028 42.103-17.256 24.192-3.584 33.243 1.145 41.39 12.764 4.883 6.967-11.521 30.41.333 53.09 4.195 8.026 34.802 48.998 34.802 51.241 0 3.54 5.526 9.118-11.549 9.738-8.99.326-51.221-17.3-74.287-36.905-11.722-9.962-33.136-34.764-35.258-36.316z"
          ></path>
          <path
            fill="#abdee5"
            strokeWidth="0.879"
            d="M-908.167 1786.503c-27.313-1.702-42.473-8.833-43.328-16.412-.327-2.902 5.03-17.24 6.368-18.89 1.484-1.834 4.851-6.376 10.12-6.674 7.064-.399 17.96 3.077 28.29 8.165 3.625 1.784 30.479-2.055 34.444-.48 7.7 3.06 15.412 3.145 15.412 5.778 0 2.805-19.55 4.374-21.678 24.647-2.77 26.377-20.121 4.459-29.628 3.866z"
          ></path>
          <path
            fill="#8fd19e"
            strokeWidth="0.879"
            d="M-945.246 1903.238c-3.609-5.65-12.998-46.39-13.52-53.325-1.003-13.335-1.69-34.125 2.9-63.422.61-3.896 58.574 6.78 70.412 14.47 14.662 9.524-7.236 39.842-12.081 49.606-2.405 4.846-18.311 23.995-26.852 28.051-8.086 3.84-16.026 32.189-20.86 24.62z"
          ></path>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "1.25" }}
            x="-434.747"
            y="2173.496"
            fill="#0e3c19"
            strokeWidth="3.538"
            fontFamily="sans-serif"
            fontSize="264.136"
            transform="scale(1.19522 .83667)"
          >
            <tspan
              x="-434.747"
              y="2173.496"
              style={{ InkscapeFontSpecification: "Arial" }}
              fill="#fff"
              fillOpacity="1"
              strokeWidth="3.538"
              fontFamily="Arial"
              fontSize="301.2"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="normal"
            >
              earth
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "1.25" }}
            x="-463.41"
            y="2235.036"
            fill="#fff"
            fillOpacity="1"
            strokeWidth="2.847"
            fontFamily="sans-serif"
            fontSize="236.887"
            transform="scale(1.12571 .88833)"
          >
            <tspan
              x="-463.41"
              y="2235.036"
              style={{ InkscapeFontSpecification: "Arial" }}
              fill="#fff"
              fillOpacity="1"
              strokeWidth="2.847"
              fontFamily="Arial"
              fontSize="236.887"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="normal"
            >
              keeper
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default Icon;