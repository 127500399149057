import React from 'react';
// import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
// import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
// import { ReactComponent as IconGithub } from './assets/icons/github.svg';

// import { ReactComponent as EarthKeeperLogo } from './assets/logo.svg';
import "./App.css";
import Icon from './icon';
// import background from './assets/chilmark 2 extract.png'
// import ConceptRender from './assets/concept_render.png'
// import FlowDiagram from './assets/flow.png'
// import KeyHighlights from './assets/key highlights.png'
// import SoftwareFlow from './assets/software flow.png'

class App extends React.Component {
    render = () => {
        return (
            <div>
                <div className="card_top">

                    <div className="content">

                        <div className="logo">
                            <Icon className='icon' />
                        </div>
                        <div className="title-holder">
                            <h1>UAV data collection, scaled.</h1>
                            {/* <p>We're in Alpha at the moment, send us an email to access more information!</p> */}
                        </div>
                        <a href="mailto:info@earthkeeper.uk">
                            <div className="cta">Contact Us</div>
                        </a>
                    </div>

                    <div className="footer">

                    </div>
                </div>
            </div >



        );
    }
}

export default App;